<template>
  <b-card>
    <b-row>
      <b-col
        md="3"
        sm="12"
      >
        <validation-provider
          v-slot="validationContext"
          vid="time"
          :rules="{ required: true }"
          name="Date"
          class="mr-1"
        >
          <label for="datepicker-from">From</label>
          <b-form-datepicker
            v-model="fromDate"
            value-as-date
            dropright
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
      <b-col
        md="3"
        sm="12"
      >
        <validation-provider
          v-slot="validationContext"
          vid="time"
          :rules="{ required: true }"
          name="Date"
        >
          <label for="datepicker-to">To</label>
          <b-form-datepicker
            v-model="toDate"
            value-as-date
            dropright
            :state="getValidationState(validationContext)"
          />
          <b-form-invalid-feedback
            :state="getValidationState(validationContext)"
          >
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <champion-page-views
          :from-date="fromDate"
          :to-date="toDate" 
        />
      </b-col>
      <b-col md="4">
        <champion-activity
          :from-date="fromDate"
          :to-date="toDate" 
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <participant-page-views
          :from-date="fromDate"
          :to-date="toDate" 
        />
      </b-col>
      <b-col md="4">
        <participant-activity
          :from-date="fromDate"
          :to-date="toDate" 
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <program-type-usage
          :from-date="fromDate"
          :to-date="toDate" 
        />
      </b-col>
      <b-col md="4">
        <program-activity
          :from-date="fromDate"
          :to-date="toDate" 
        />
      </b-col>
      <b-col md="4">
        <active-users
          :from-date="fromDate"
          :to-date="toDate" 
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import {
  getValidationState,
} from "@/libs/utils";
import ChampionPageViews from './ChampionPageViews.vue';
import ChampionActivity from './ChampionActivity.vue';
import ParticipantPageViews from './ParticipantPageViews.vue';
import ParticipantActivity from './ParticipantActivity.vue';
import ProgramTypeUsage from './ProgramTypeUsage.vue';
import ProgramActivity from './ProgramActivity.vue';
import ActiveUsers from './ActiveUsers.vue';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormDatepicker,
    ValidationProvider,
    ChampionPageViews,
    ChampionActivity,
    ParticipantPageViews,
    ParticipantActivity,
    ProgramTypeUsage,
    ProgramActivity,
    ActiveUsers,
  },
  data() {
    return {
      fromDate: this.lastMonth(),
      toDate: new Date(),
    };
  },
  methods: {
    lastMonth() {
      const d = new Date();
      const dayOfMonth = d.getDate();
      d.setMonth(d.getMonth() - 3);
      if (d.getDate() !== dayOfMonth) {
        d.setDate(0);
      }
      return d;
    }
  },
  setup() {
    return {
      getValidationState,
    };
  }
};
</script>

<style>
</style>