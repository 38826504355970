<template>
  <div>
    <apexchart
      v-show="!isLoading"
      type="donut"
      :options="options"
      :series="options.series"
      height="350"
    />
    <aom-skeleton-loader 
      v-if="isLoading"
      :loader-type="loaderTypes.SHORT" 
    />
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import { platformMetricsService } from '@services';
import { dateObjectRangeToIsoString } from '@/libs/utils';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { loaderTypes } from '@models';

export default {
  components: {
    AomSkeletonLoader
  },
  props: {
    fromDate: {
      type: Date,
      default: null
    },
    toDate: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      isLoading: true,
      options: {
        title: {
          text: 'Program Usage By Type'
        },
        series: [],
        labels: [],
        dataLabels: {
          enabled: true,
        },
        chart: {
          height: 350,
          type: 'donut',
        }
      },
    };
  },
  watch: {
    fromDate: {
      immediate: true, 
      handler () {
        this.getData();
      }
    },
    toDate: {
      immediate: true, 
      handler () {
        this.getData();
      }
    }
  },
  methods: {
    getData:  _debounce(async function () {
      try {
        this.isLoading = true;
        const response = await platformMetricsService.getProgramTypeUsage(...dateObjectRangeToIsoString(this.fromDate, this.toDate));
        this.options = {...this.options, series: response.data.series, labels: response.data.labels };
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    }, 1000)
  },
  setup() {
    return {
      loaderTypes
    };
  }
};
</script>
