<template>
  <div>
    <apexchart
      v-show="!isLoading"
      type="bar"
      :options="options"
      :series="options.series"
      height="350"
    />
    <aom-skeleton-loader 
      v-if="isLoading"
      :loader-type="loaderTypes.SHORT" 
    />
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import { platformMetricsService } from '@services';
import { dateObjectRangeToIsoString } from '@/libs/utils';
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { loaderTypes } from '@models';

export default {
  components: {
    AomSkeletonLoader
  },
  props: {
    fromDate: {
      type: Date,
      default: null
    },
    toDate: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      isLoading: true,
      options: {
        title: {
          text: 'Participant Page Views'
        },
        series: [],
        xaxis: {},
        yaxis: {
          title: {
            text: 'API Requests'
          }
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        fill: {
          opacity: 1
        },
      },
    };
  },
  watch: {
    fromDate: {
      immediate: true, 
      handler () {
        this.getData();
      }
    },
    toDate: {
      immediate: true, 
      handler () {
        this.getData();
      }
    }
  },
  methods: {
    getData:  _debounce(async function () {
      try {
        this.isLoading = true;
        const response = await platformMetricsService.getParticipantPageViews(...dateObjectRangeToIsoString(this.fromDate, this.toDate));
        this.options = {...this.options, series: response.data.series};
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    }, 1000)
  },
  setup() {
    return {
      loaderTypes
    };
  }
};
</script>
